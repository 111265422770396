import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../layouts'
import Prism from 'prismjs'

class PostTemplate extends Component {
  componentDidMount() {
    console.log(Prism)
    Prism.highlightAll()
    console.log(Prism.languages)
  }

  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout>
        <article>
          <header>
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </header>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </article>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
    }
  }
`
